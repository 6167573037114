@font-face {
  font-family: 'Insigna';
  font-style: normal;
  font-weight: 400;
  src: local('Insigna'), url('./assets/Insignia.otf') format('woff');
}

button[aria-label*='add'] {
  display: none !important;
}

body {
  font-family: Roboto;
}
