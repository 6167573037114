.App {
  text-align: center;
}

.App-logo {
  filter: drop-shadow(1px 1px 0px #fff);
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

/* label + .MuiInput-formControl-55 {
  margin-top: 32px !important;
  margin-left: 7px;
} */

.MuiInputLabel-formControl-41 {
  left: 10px !important;
}

.covenants-form label span,
.covenants-form p,
.covenants-form h6,
.Home-dialogForm-342 .MuiTypography-body2-188 {
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
}

.covenants-form h6 {
  font-size: 18px;
}

.covenants-form label span {
  font-size: 14px;
}

.MuiFormControlLabel-labelPlacementStart-439 {
  margin: 25px 0 0;
}

.marginRight span {
  min-width: 12%;
}

.flex {
  display: flex;
  text-align: center;
}

.MuiFormLabel-root-47 {
  text-align: left;
}
.auditPopUp {
  margin: 10px 0;
}

/* .covenants-form .timeSheetTable [class^='MuiTableCell-root'],
[class*='MuiTableCell-root'] */
.timeSheetTable div table thead tr th,
.timeSheetTable div table tbody tr td {
  padding: 0;
}

/* .covenants-form .timeSheetTable th[class^='MuiTableCell-alignRight'],th
th[class*='MuiTableCell-alignRight'],
.covenants-form .timeSheetTable td[class^='MuiTableCell-alignRight'],
td[class*='MuiTableCell-alignRight'],
.covenants-form .timeSheetTable th {
  flex-direction: inherit;
  text-align: left;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
